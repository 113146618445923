import { useNavigate } from "react-router-dom";

function Header(){
    const navigate = useNavigate();
    return (
        <div className="header" onClick={() => navigate('/query')}>
            <h1>The Cancer Genome Noise Atlas <span style={{fontSize: '0.7rem'}}> (Version 1.0)</span> </h1>
        </div>
    );
}

export default Header;