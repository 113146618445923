import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import toTitleCase from "../../utils/toTitleCase";
function SampleTable ({dataDocs}) {
    return (
        <TableContainer sx={{width: 'fit-content', maxWidth: '97%', overflowX: 'auto',  margin: '30px auto', border: '1px solid silver', marginTop: '5vh'}}>
            <Table size="small" >
            <TableHead sx={{backgroundColor: 'silver'}} >
                <TableRow>
                    <TableCell colSpan={2} sx={{fontWeight:'600', fontSize: '15px', position:'sticky', left:0}}>
                        Sample Counts
                    </TableCell>
                    <TableCell colSpan={dataDocs.length-1}></TableCell> 
                </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                <TableCell sx={{position:'sticky', left:0, backgroundColor: 'white'}} > Site </TableCell>
                {dataDocs.map(doc => 
                    <TableCell align="center" >{toTitleCase(doc.cancer_type)}</TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell sx={{position:'sticky', left:0, backgroundColor: 'white'}} > Samples </TableCell>
                {dataDocs.map(doc => 
                    <TableCell align="center" >{doc.num_samples}</TableCell>
                )}
            </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SampleTable;