export default function generateCSVData(displayField, docs, geneIDs, geneNames, noiseMethod) {
    const header1 = ['geneID', 'geneName'];
    const header2 = docs.map(doc => doc.cancer_type);
    const header = [...header1, ...header2];
    let csv = [];
    csv.push(header);
    for (let i=0; i<geneIDs.length; i++){
        let row = [geneIDs[i].split('-').join('.'), geneNames[i]];
        for (let doc of docs){
            let x = doc[displayField][geneIDs[i]].value;
            if (noiseMethod == 'cvs')
                x *= x;
            row.push(x.toString());
        }
        csv.push(row);
    }
    return csv;
}