export default function GeneSelectionMain({showGeneQuery}) {
    return (
<>
            <div className="filter-header">
                <h3 className="selection-title"> Gene Selection </h3>
            </div>          
            <p>Choose method to select the set of genes to analyze.</p>   
            <div className="gene-selection-flex">
                <div className="gene-selection-left">
                    <h3 className="selection-title sub"> Gene Networks</h3>  
                    <div className="option-pair">
                        <p>Select a gene network from the results of the functional enrichment analysis on your input gene(s). The enrichment analysis identifies networks of genes/proteins that are likely associated with your set of input gene(s).</p>     
                        <button className="analysis-button" onClick={() => showGeneQuery('enrich')}>Query by Enrichment</button>
                    </div>
                    <div className="option-pair">
                        <p> Query the gene network of <em>k</em> nearest interaction partners of the proteins encoded by the input gene(s). These interactions can be physical or functional, and are as derived by STRING-db from various sources, including experimental data, computational predictions, and text mining.  </p>     
                        <button className="analysis-button" onClick={() => showGeneQuery('neighbors')}>Query by Nearest Neighbors</button>
                    </div>
                    <div className="option-pair">
                        <p>Query a gene network by its Database ID [GO, Kegg]. Enter a database ID for a biological component, process, or function to perform analysis on genes associated with that network. </p>     
                        <button className="analysis-button" onClick={() => showGeneQuery('db')}>Query by Database ID</button>
                    </div>
                </div>
            <div className="divider"></div>
            <div className="gene-selection-right">
                <h3 className="selection-title sub"> Custom Gene List</h3>  
                <div className="option-pair">
                    <p>Upload a file containing a list of gene symbols to retrieve results directly for these genes.</p>     
                    <button className="analysis-button two" onClick={() => showGeneQuery('file')}>Query by Custom Gene List</button>
                </div>
            </div>
            </div>
        </>
    );
}