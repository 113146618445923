import { cancers } from "../../data/cancers";
import { useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import { applyCancerFilter } from "../../store/dataSlice.js";

const normalButtonStyle = {fontSize: '0.95rem', height: '2rem', width: '5rem'}

function IndepCancerSelect({onApply, notPopup}){
    const currSelectedCancers = useSelector(state => state.data.currentFilters.cancers);
    let initCancerCheck = {};
    cancers.forEach(cancer => {initCancerCheck[cancer] = false});
    currSelectedCancers.forEach(cancer => initCancerCheck[cancer] = true);
    const [selectedCancers, setSelectCancers] = useState(initCancerCheck);
    const [err, setErr] = useState("");
    const dispatch = useDispatch();
    const [select, setSelect] = useState(true);

    //console.log("selected cancers: ", selectedCancers);

    const handleToggle = () => {
        if (select)
            handleSelectCancer("all");
        else
            handleSelectCancer("none");
        setSelect(s => !s);
    }

    const handleApply = (i) => {
        let filters = [];
        for (let key in selectedCancers)
            if (selectedCancers[key] == true){
                filters.push(key)
            }
        if (filters.length > 0){
            setErr("")
            dispatch(applyCancerFilter(filters));
            if (onApply)
                onApply();
        }
        else
            setErr("No Primary Sites Selected.")
    }

    const handleSelectCancer = (option) => {
        let newSelection;
        if (option == "all"){
            newSelection = {...selectedCancers};
            for (let cancer in selectedCancers)
                newSelection[cancer] = true;
        }
        else if (option == "none"){
            newSelection = {...selectedCancers};
            for (let cancer in selectedCancers)
                newSelection[cancer] = false;
        }
        else {
            newSelection = {
                ...selectedCancers,
                [option]: !selectedCancers[option],
            };
        }
        //console.log('option: ', option);
        //console.log("new selection: ", newSelection);
        setSelectCancers(newSelection);
        if (err)
            setErr("");
    };

    return (
        <div className="filter-panel-container popup" style={notPopup ? {paddingLeft: '0'} : {}}>
            <div className="filter-group" style={notPopup ? {width:'100%'} : {}}>
            <div className="filter-header">
                    <h3 className="selection-title" style={notPopup ? {fontSize: '0.95rem'} : {}} > Primary Sites </h3>
            </div>
            <div className="filter-buttons">
                    <button onClick={() => handleSelectCancer("all")}> Select All</button>
                    <button onClick={() => handleSelectCancer("none")}> Deselect All</button>
                </div>
                <div className="filter-options-container">
                {cancers.map(cancer => 
                <label key={cancer} htmlFor={cancer+"cancer"} className={selectedCancers[cancer] ? "checked" : ""} ><input id={cancer+"cancer"} type="checkbox" onChange={() => handleSelectCancer(cancer)} checked={selectedCancers[cancer]} /> {cancer}</label>
                )}
                </div>
            </div>
            <button className="analysis-button apply" onClick={handleApply} style={notPopup ? normalButtonStyle : {}} >Apply</button>
            {err && <p style={{color:"red", margin: '0 auto'}}>{err}</p>}
        </div>
    )
}

export default IndepCancerSelect;