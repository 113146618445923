function Footer () {
    return (
        <div className="footer">
            <div className="last">
                <span>© 2023 Biomedical Informatics and Engineering Research Lab (BIRL), Lahore University of Management Sciences (LUMS)</span>
            </div>
        </div>
    )
}

export default Footer;