import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import IndepCancerSelect from "../components/v2/IndepCancerSelect";
import { applyCancerFilter, saveFilterData } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

function GNNeighbor ({cancers}) {
    const [isOpen, setIsOpen] = useState(false);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [networkType, setNetworkType] = useState('functional');
    const [addNodes, setAddNodes] = useState('default');
    const [score, setScore] = useState('default');
    const [specifyScore, setIsSpecify1] = useState(false);
    const [specifyNodes, setIsSpecify2] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const NTtip = "Functional networks are based on shared biological functions. Physical Networks are based on direct physical binding.";
    const scoreTip = "Threshold of significance to include a interaction, a number between 0 and 1000 (default depends on the network).";
    const ANtip = "Expand the default network by a number of genes based on their confidence score.";
    const handleOptionChange1 = (event) => {
        setIsSpecify1(event.target.value === 'specify');
    };

    const handleOptionChange2 = (event) => {
        setIsSpecify2(event.target.value === 'specify');
    };

    const togglePopup = () => {
      setIsOpen(!isOpen);
    };

    const onGo = () => {
        if (cancers.length > 0) {
        let identifiers = input.replaceAll(' ', '').split(',').join('&genes=');
        let url = "https://tcgna.cancerlogics.com/filter?genes="+identifiers+"&NN=true";
        //let url = "http://localhost:8000/filter?genes="+identifiers+"&NN=true";
        if (specifyNodes)
            url += "&add_nodes="+addNodes;
        if (specifyScore)
            url += "&score="+score;
        if (networkType != 'functional')
            url += "&network_type=physical";
        //console.log(url);
        setLoading(true);
        fetch(url)
            .then(data => data.json())
            .then(json => {
                const {geneNames, geneIDs, filterDocs, notFoundGenes} = json;
                const currentFilters = {
                    geneIDs,
                    geneNames
                };
                dispatch(applyCancerFilter(cancers));
                dispatch(saveFilterData({filterDocs, currentFilters, notFoundGenes, queryType: 'Nearest Neighbors', queryContent: input.replaceAll(' ', '').split(',')}));
                navigate('/results');
            });
        }
        else    
            setErrMsg("No sites selected.")
    }

    return (
        <>
            <div className="gene-query-page">
                <div className="filter-header">
                    <h3 className="selection-title">Gene Network Selection</h3>
                    <h3 className="selection-subtitle">Nearest Neighbors</h3>
                </div>
                <div className="box sites-box">
                    <div className="text-content tsites">
                        <h2 className="title">Sites Selected: </h2>
                    </div> 
                    <strong className="input-box sites" >{cancers.length > 0 ? cancers.join(', ') : 'None'}</strong>
                </div>
                <div className="box NN">
                    <div className="text-content">
                        <h2 className="title">Nearest Neighbors</h2>
                        <p className="description">Query on nearest neighbors network of input genes.</p>
                    </div>
                    <div className="NN-inputs">
                        <div className="p1">
                            <label htmlFor="geneNames" className='p-title'>Gene Name(s) </label>
                            <p className="direction"> Enter gene name(s)/symbol(s). Separate symbols by commas.</p>
                            <input id="geneNames" type="text" className="input-box NN-input" placeholder="e.g. p53, cdk2" value={input} onChange={(e) => setInput(e.target.value)} autoComplete="off" />
                        </div>
                        <p onClick={() => setAdvanced(s => !s)} className='advanced' >Advanced Options</p>
                        {advanced &&
                        <div className="parameters">
                            <div className="p2">
                                <div><label htmlFor="networkType" className='p-title' style={{display:'inline-flex'}}> Network Type </label><span style={{display: 'inline-flex', marginLeft: '5px'}}><Tooltip title={NTtip} ><InfoIcon fontSize="8px" /></Tooltip></span></div>        
                                <label><input id="functional" type="radio" name="networkType" value="functional" checked={networkType=="functional"} onChange={()=>setNetworkType("functional")} /> Functional </label>
                                <label><input id="physical" type="radio" name="networkType" value="physical" checked={networkType=="physical"} onChange={()=>setNetworkType("physical")} /> Physical </label>
                            </div>
                            <div className="p3">
                            <div><label htmlFor="score" className='p-title' style={{display:'inline-flex'}}> Score Threshold </label><span style={{display: 'inline-flex', marginLeft: '5px'}}><Tooltip title={scoreTip} ><InfoIcon fontSize="8px" /></Tooltip></span></div>        
                            <label><input type="radio" name="score" value="default" checked={!specifyScore} onChange={handleOptionChange1} /> Default </label>
                                <div className="p4">
                                    <label><input type="radio" name="score" value="specify" checked={specifyScore} onChange={handleOptionChange1} /> Specify: </label> 
                                    <input type="range" min={0} defaultValue={700} max={1000} className="numeric-input" disabled={!specifyScore}  value={score} onChange={e => setScore(e.target.value)}  />
                                    <span className="current-label">{score}</span>  
                                </div>
                            </div>
                        <div className="p3">
                        <div><label htmlFor="add-nodes" className='p-title' style={{display:'inline-flex'}}> Add Nodes </label><span style={{display: 'inline-flex', marginLeft: '5px'}}><Tooltip title={ANtip}><InfoIcon fontSize="8px" /></Tooltip></span></div>        
                        <label><input type="radio" name="add-nodes" value="default" checked={!specifyNodes} onChange={handleOptionChange2} /> Default </label>
                            <div><label><input type="radio" name="add-nodes" value="specify" checked={specifyNodes} onChange={handleOptionChange2} /> Specify: </label><input type="number" className="numeric-input" disabled={!specifyNodes} defaultValue={10} value={addNodes} min={0} onChange={e => setAddNodes(e.target.value)} /></div>
                        </div>
                        </div>
                        }
                        <button  className="analysis-button NN" onClick={onGo}> Go </button>
                    </div>
                </div>
                {errMsg && <p style={{color:'red'}}>{errMsg}</p>}
                    {isOpen && (
                <div className="popup-overlay" onClick={togglePopup}>
                <div className="popup-content" onClick={e => e.stopPropagation()}>
                   <IndepCancerSelect onApply={togglePopup} />
                </div>
                </div>
            )}
            {loading && <div className="loading"> <CircularProgress /> </div>}
            </div>
        </>
    )
}

export default GNNeighbor;