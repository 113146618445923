import NoiseAnalyse from "./NoiseAnalyse"

export default function NoiseDiffAnalysis ({docs, displayField}) {
    let sorted_docs = docs.sort((d1,d2) => d1.cancer_type > d2.cancer_type)
    console.log("sorted: ", sorted_docs)
    return (
        <div>
            <h3>Differential Noise Analysis</h3>
            <div className="noise-anlysis-container" > 
            {sorted_docs.map((d,i) => 
                {
                    let site = sorted_docs[i].cancer_type.split(' ')[0];
                    if (i%2 == 0)
                        return <NoiseAnalyse key={site} normal={sorted_docs[i][displayField]} tumor={sorted_docs[i+1][displayField]} site={site} />
                }
            )}
            </div>
        </div>
    )
}