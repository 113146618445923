import { create, all } from 'mathjs'

export default function generateHeatmapData(cancerDocs, displayField, geneIDs, noiseMethod){
    const math = create(all,  {})
    let gene_wise_data = {};
    geneIDs.forEach(id => {gene_wise_data[id] = []});
    for (let doc of cancerDocs){
        for (let i=0; i<geneIDs.length; i++){
            let x = doc[displayField][geneIDs[i]].value;
            if (noiseMethod == 'cvs')
                x *= x;
            gene_wise_data[geneIDs[i]].push(x);
        }
    }
    //console.log("unnormalised gene wise: ", Object.values(gene_wise_data));
    let heatmapdata =  Object.values(gene_wise_data);
    let normalisedData = [];
    for (let i=0; i<heatmapdata.length; i++){
        if (heatmapdata[i].length > 0){
        let mean = math.mean(heatmapdata[i]);
        let std = math.std(heatmapdata[i]);
        //console.log("mean: ", mean, "std: ", std);
        if (std == 0){
            normalisedData.push(heatmapdata[i]);
        }
        else{
            let normalised = heatmapdata[i].map(x => (x-mean)/std);
            normalisedData.push(normalised);
        }
        }
    }
    //console.log("normalised data: ", normalisedData);
    return normalisedData;
}