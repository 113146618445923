import { useState } from "react";
import Header from "../components/Header";
import TabsBar from "../components/v2/TabsBar";
import { useDispatch, useSelector } from "react-redux";
import IndepCancerSelect from "../components/v2/IndepCancerSelect";
import { applyCancerFilter, saveFilterData } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

function GFile ({cancers}) {
    const [fileData, setFileData] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const togglePopup = () => {
      setIsOpen(!isOpen);
    }; 

    const onGo = () => {
        if (cancers.length > 0) {
        let url = "https://tcgna.cancerlogics.com/filter?genes=";
        //let url = "http://localhost:8000/filter?genes=";
        url += fileData[0];
        fileData.slice(1).forEach(term => url += "&genes=" + term)
        setLoading(true);
        fetch(url)
        .then(data => data.json())
        .then(json => {
            const {geneNames, geneIDs, filterDocs, notFoundGenes} = json;
            const currentFilters = {
                geneIDs,
                geneNames
            };
            dispatch(applyCancerFilter(cancers));
            dispatch(saveFilterData({filterDocs, currentFilters, notFoundGenes, queryType:'Custom Gene List', queryContent: fileData}));
            navigate('/results');
        })
    }
    else    
        setErrMsg("No sites selected.")
    }

    const handleFileChange = async (e) =>{
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => { 
            const text = (e.target.result);
            const data = text.split(/\r?\n/);
            //console.log(data);
            setFileData(data);
        };
        reader.readAsText(e.target.files[0]);

        if (errMsg)
            setErrMsg("");
    }
    return (
        <>
            <div className="gene-query-page">
                <div className="filter-header">
                    <h3 className="selection-title">Gene Selection </h3>
                    <h3 className="selection-subtitle">File Upload</h3>
                </div>
                <div className="box sites-box">
                    <div className="text-content tsites">
                        <h2 className="title">Sites Selected: </h2>
                    </div> 
                    <strong className="input-box sites" >{cancers.length > 0 ? cancers.join(', ') : 'None'}</strong>
                </div>
                <div className="box">
                    <div className="text-content">
                        <h2 className="title">Upload File</h2>
                        <p className="description">Upload a .txt file containing one gene symbol per line.</p>
                    </div>
                    <div className="input-box-container">
                    <input
                    type="file" id="file-input"  accept=".txt" onChange={handleFileChange} className="file-input" />
                    </div>
                    </div>
                    {fileData && 
                    <div className="file-content box">
                        <h3>File Contents: </h3>
                        <ul>
                        {fileData.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                        </ul>
                        <button className="analysis-button go" onClick={onGo}>Go</button>
                    </div>}
                    {errMsg && <p style={{color:'red'}}>{errMsg}</p>}
                    {isOpen && (
                <div className="popup-overlay" onClick={togglePopup}>
                <div className="popup-content" onClick={e => e.stopPropagation()}>
                   <IndepCancerSelect onApply={togglePopup} />
                </div>
                </div>
            )}
            {loading && <div className="loading"> <CircularProgress /> </div>}
            </div>
        </>
    )
}

export default GFile;