import { useSelector } from "react-redux";
import { autocomplete_url, specie_suffix } from "../data/urls";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import toTitleCase from "../utils/toTitleCase"

export default function NoiseAnalyse ({normal, tumor, site}) {
    let geneNames = useSelector(state => state.data.currentFilters.geneNames);
    let geneIDs = useSelector(state => state.data.currentFilters.geneIDs);
    const [enrich, setEnrich] = useState(null);
    //------------------------------------------
    // this function just gets the 2 bars
    // for each pair (2 bars)
        // for each gene (2 block x y) find tumor/normal cv ratio 
        // 2 bars get reduced to just one bar of ratios
    // sort the ratios
    // pick the values that are >= 90% of max value
    // display these genes as most diff noise in that site
    // enrich that gene set and display enichment result

    console.log("normalDict: ", normal)
    console.log("tumorDict: ", tumor)
    let ratios = [];
    let r, r1, r2;
    for (let i=0; i<geneIDs.length; i++){
        r1 = tumor[geneIDs[i]].value/normal[geneIDs[i]].value;
        r2 = normal[geneIDs[i]].value/tumor[geneIDs[i]].value;
        r = Math.max(r1,r2);
        ratios.push({'id' :geneIDs[i], 'name': geneNames[i], 'val': r});
    }
    console.log("ratios: ", ratios)

    ratios.sort((a, b) => b.val - a.val);
    const max_r = ratios.reduce((max, r) => {
        return r.val > max ? r.val : max;
      }, 0);
    
    console.log("ratios sorted: ", ratios);
    ratios = ratios.filter(r => r.val >= 0.7*max_r);
    console.log("ratios with thres ", ratios);
    let diff_genes = ratios.map(r => r.name);
    let url = autocomplete_url + diff_genes.join("%0d") + specie_suffix;

    useEffect(() => {
        let ignore = false;
        console.log("diffgenes: ", site, diff_genes.length > 2)
        if (diff_genes.length > 2){
            console.log("in for ", site)
            fetch(url)
            .then(data => data.json())
            .then(json => {
                if (!ignore){
                    let topEntries = [];
                    let counter = 0;
                    let currentCategory = null;

                    for (const entry of json) {
                        if (entry.category !== currentCategory){ 
                            currentCategory = entry.category;
                            counter = 0;
                        }
                        if (counter < 2) {
                            counter++;
                            topEntries.push(entry)
                        }
                    }
                    setEnrich(topEntries)
                }
            })
            .catch(err => setEnrich([]));
        }
        return () => {ignore = true}
    }, [site])

    return (
        <Box sx={{display:'flex', 'flexDirection':'column', 'gap' :'10px', borderRadius: 5, backgroundColor: 'lightsalmon', width: '20rem', padding:'20px'}}>
            <h3 style={{textAlign:'center'}}>{toTitleCase(site)}</h3>
            <h4 style={{textAlign:'center'}}> Genes wth Differential Noise </h4>
            <div>
            {diff_genes.map(gene => 
                <p style={{textAlign:'center'}} >{gene}</p>
            )}
            </div>
            {enrich && 
            <div>
                <h4 style={{textAlign:'center'}}> Enrichment </h4>
                {enrich.map(option => 
                <li key={option.term} className="autocomplete-option" style={{cursor: 'default'}} >
                        <span className="option-term">{option.term} [{option.category}]</span>
                        <span className="option-desc">{option.description}</span>
                </li>)
                }     
            </div>
            }
        </Box>
    )

    
}