import './App.css';
import Header from './components/Header';
import Main from './components/Main';
import TabsBar from './components/v2/TabsBar';
import GNEnrich from './pages/GNEnrich';
import QueryPage from './pages/QueryPage';
import GNNeighbor from './pages/GNNeighbor';
import GNdb from './pages/GNdb';
import GFile from './pages/GFile';
import ResultsPage from './pages/ResultsPage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ResultsPage2 from './pages/ResultsPage2';

function App() {
  const docs = useSelector(state => state.data.docs);
  let cancers = useSelector(state => state.data.currentFilters.cancers);
  const results = docs.length > 0 ? true : false;
  const cancerFiltered = cancers.length > 0 ? true : false;

  return (    
    <Routes>
      <Route index element={<Navigate to='/query' />} />
      <Route path='results' element={results ? <ResultsPage2 /> : <Navigate to='/' />} />
      <Route path='query'element={<QueryPage />} />
      <Route path='query/network-enrich' element={<GNEnrich />} />
      <Route path='query/network-nearest-neighbor' element={cancerFiltered ? <GNNeighbor /> : <Navigate to='/query' />} />
      <Route path='query/network-db' element={cancerFiltered ? <GNdb /> : <Navigate to='/query' />} />
      <Route path='query/gene-file' element={cancerFiltered ? <GFile /> : <Navigate to='/query' />} />
    </Routes>
  );
}

//<Main />

export default App;
