import {useEffect, useState} from "react";
import { autocomplete_url, specie_suffix } from "../../data/urls.js";
import selectAutocompleteOptions from "../../utils/selectAutocompleteOptions.js";
import {useDispatch} from "react-redux";
import { applyCancerFilter, saveFilterData } from "../../store/dataSlice.js";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { cancers } from "../../data/cancers.js";

function AutocompleteGN({setLoading, cancers, setErrMsg}){
    const [geneQuery, setGeneQuery] = useState("")
    const [autocomplete, setAutocomplete] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const handleSelectTerm = (option) => {
        if (cancers.length > 0) {
            let url;
            if (option.term.slice(0,2) == "GO"){
                let id = "GO:" + option.term.split(':')[1];
                //console.log("sending req for ",id);
                url = "https://tcgna.cancerlogics.com/filter?genes="+id+"&ID=true";
                //url = "http://localhost:8000/filter?genes="+id+"&ID=true";
            }
            else if (option.category == "KEGG"){
                //url = "http://localhost:8000/filter?genes="+option.term+"&ID=true"
                url = "https://tcgna.cancerlogics.com/filter?genes="+option.term+"&ID=true"
            }

            setGeneQuery("");
            setLoading(true);
                fetch(url)
                .then(data => data.json())
                .then(json => {
                    const {geneNames, geneIDs, filterDocs, notFoundGenes} = json;
                    const currentFilters = {
                        geneIDs,
                        geneNames
                    };
                    dispatch(applyCancerFilter(cancers));
                    dispatch(saveFilterData({filterDocs, currentFilters, queryType:'Enrichment Analysis', queryContent: option, notFoundGenes}));
                    navigate('/results')
                })
        }
        else
            setErrMsg('No Sites Selected.')
    }

    useEffect(() => {
        let ignore = false;
        if (geneQuery.length > 2){
            let identifiers = geneQuery.replaceAll(' ', '').split(',').join("%0d");
            let url = autocomplete_url + identifiers + specie_suffix;
            //console.log("autocomplete: ", url);
            fetch(url)
            .then(data => data.json())
            .then(json => {
                if (!ignore)
                    setAutocomplete(selectAutocompleteOptions(json))
            })
            .catch(err => setAutocomplete([]));
        }
        return () => {ignore = true}
    }, [geneQuery])

    return (
        <div className="input-box-container">
            <input id="gene-input" type="text" placeholder="e.g. p53, cdk2" onChange={(e) => setGeneQuery(e.target.value)} value={geneQuery} autoComplete="off" />
            {autocomplete &&
            <div className="autocomplete-dropdown-container">
            <ul className="autocomplete-gene-options">
                {geneQuery != "" && autocomplete.length > 0 ?
                autocomplete.map(option => 
                <li key={option.term} className="autocomplete-option" onClick={() => handleSelectTerm(option)}>
                        <span className="option-term">{option.term} [{option.category}]</span>
                        <span className="option-desc">{option.description}</span>
                </li>
                ) : geneQuery !="" &&
                <li className="autocomplete-option">
                    <span className="option-desc">No Results</span>
                </li>
                }  
            </ul>
            </div>
            }
        </div>
    )
}

export default AutocompleteGN;