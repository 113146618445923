import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import IndepCancerSelect from "../components/v2/IndepCancerSelect";
import { applyCancerFilter, saveFilterData } from "../store/dataSlice";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

function GNdb ({cancers}) {
    const [isOpen, setIsOpen] = useState(false);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const togglePopup = () => {
      setIsOpen(!isOpen);
    };

    const onGo = () => {
        if (cancers.length > 0) {
            let id = "GO:" + input.split(':')[1];
            //console.log("sending req for ",id);
            let url = "https://tcgna.cancerlogics.com/filter?genes="+id+"&ID=true";
            //let url = "http://localhost:8000/filter?genes="+id+"&ID=true";
            setLoading(true);
            fetch(url)
            .then(data => data.json())
            .then(json => {
                const {geneNames, geneIDs, filterDocs, notFoundGenes} = json;
                const currentFilters = {
                    geneIDs,
                    geneNames
                };
                dispatch(applyCancerFilter(cancers));
                dispatch(saveFilterData({filterDocs, currentFilters,notFoundGenes, queryType: 'Database ID', queryContent: input}));
                navigate('/results');
            });
        }
        else    
            setErrMsg("No sites selected.")
    }

    return (
        <>
            <div className="gene-query-page">
                <div className="filter-header">
                    <h3 className="selection-title">Gene Network Selection</h3>
                    <h3 className="selection-subtitle">Database ID</h3>
                </div>
                <div className="box sites-box">
                    <div className="text-content tsites">
                        <h2 className="title">Sites Selected: </h2>
                    </div> 
                    <strong className="input-box sites" >{cancers.length > 0 ? cancers.join(', ') : 'None'}</strong>
                </div>
                <div className="box">
                    <div className="text-content">
                        <h2 className="title">Database ID</h2>
                        <p className="description">Enter database ID [GO] of gene network. e.g. GO:0097190 </p>
                    </div>
                    <div className="input-box-container flex">
                        <input type="text" className="input-box" placeholder="e.g. GO:0097190" value={input} onChange={(e) => setInput(e.target.value)} autoComplete="off" />
                        <button  className="analysis-button small" onClick={onGo}> Go </button>
                    </div>
                </div>
                {errMsg && <p style={{color:'red'}}>{errMsg}</p>}
                    {isOpen && (
                <div className="popup-overlay" onClick={togglePopup}>
                <div className="popup-content" onClick={e => e.stopPropagation()}>
                   <IndepCancerSelect onApply={togglePopup} />
                </div>
                </div>
            )}
            {loading && <div className="loading"> <CircularProgress /> </div>}
            </div>
        </>
    )
}

export default GNdb;