import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '@mui/material';

function ResultsFooter () {
    const scrollToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        });
    };
    return (
        <div className="results-footer">
            <div className="last">
                <span>© 2023 Biomedical Informatics and Engineering Research Lab (BIRL), Lahore University of Management Sciences (LUMS)</span>
            </div>
            <Button variant='contained' onClick={scrollToTop} ><KeyboardArrowUpIcon /></Button>
        </div>
    )
}

export default ResultsFooter;